/* General styles */
body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background-color: #f5f5f5;
  margin: 0;
  padding: 0;
}

.App {
  max-width: 900px;
  margin: 20px auto;
  padding: 20px;
  background-color: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

header {
  text-align: center;
  margin-bottom: 40px;
}

h1 {
  color: #333;
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 10px;
}

main {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.actions-section,
.file-list-section {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.actions-section > div,
.file-list-section {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 20px;
}

input[type="file"],
input[type="text"] {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 100%;
  max-width: 250px;
  margin: 5px 0;
}

button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #0056b3;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.file-manager-container {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
}

.file-manager-container h2 {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
}

ul {
  list-style-type: none;
  padding: 0;
}

ul li {
  background: #f9f9f9;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

a {
  color: #007bff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

input,
select {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 100%;
  max-width: 250px;
}

h3 {
  margin-bottom: 15px;
  color: #333;
}

.storage-usage-bar {
  height: 30px;
  width: 100%;
  background-color: #e0e0df;
  border-radius: 5px;
  overflow: hidden;
}

.storage-usage-bar div {
  height: 100%;
  background-color: #76c7c0;
  text-align: center;
  color: white;
  line-height: 30px;
  transition: width 0.4s ease;
}
